import { title } from "./../../../../../assets/jss/material-kit-react.js";

const workStyle = {
  section: {
    padding: "70px 0",
  },
  title: {
    ...title,
    marginBottom: "50px",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center",
    fontSize: "35px",
  },
  subdescr: {
    color: "#3C4858",
    //textAlign: "left",
    fontSize: "19px",
    marginBottom: "30px",
    textAlign: "justify",
  },
  textCenter: {
    textAlign: "center",
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px",
  },
};

export default workStyle;
